// Media query breakpoints
const breakpoints = {
  'sm':576,
  'md':768,
  'lg':992,
  'xl':1200,
  'xxl':1600
}
/* Navbar script code (For Mobile & Tablet)*/
if($(window).width() <= breakpoints.lg){
  // Disable mouseleave event on mobile for mega menu
  $(".nav__megamenu").off("mouseleave");
  $(".nav__menu-item").off("mouseleave mouseenter");
  /* 
      Public navbar functionnality 
  */
  $(".meganav--main .nav__menu-item--nested, .meganav--main .nav__megamenu-col-list-item--nested").on("click", function(e){
      e.preventDefault();
      // Hide all submenus 
        const level = $(this).attr("aria-level") != null ? $(this).attr("aria-level") : $(this).closest('.nav__menu-sub').attr("aria-level");
        const backLink = $(".meganav .nav__menu-item--back");
        const linkText = level == "1" ? "Menu":`${$(`li[aria-level="${parseInt(level)-1}"]:visible`).attr('aria-label')}`;
        $(`li[aria-level="${level}"]`).not($(this)).css('display','none');
        $(this).find(">.nav__menu-item-link,>.nav__menu-sub-list-item-link").css('display','none');
        $(this).find(">.nav__menu-sub").css('display','block');
        $(`li[aria-level="${parseInt(level)+1}"]`).css('display','block');
        $(".nav__menu-item--back").data('back',level);
        $(".nav__menu-item--back").find('span').html(`Back to ${linkText}`);
        backLink.data('back', level);
        backLink.find('span').html(`Back to ${linkText}`);
  });
  $(".nav__menu-sub-list-item").on("click", function(e){
    e.stopPropagation();
  });
  // Go back to the previous menu 
  $(".meganav--main .nav__menu-item--back").click(function(e){
      e.preventDefault();
      const level = parseInt($(this).data("back"));
      if(level >= 1){
        // Hide current level items
        $(`[aria-level="${level+1}"]`).css('display','none');
        // Show previous level items
        $(`li[aria-level="${level}"],li[aria-level="${level}"]>a`).css('display','block');
        // Decrease back level
        $(this).data('back',level-1);
        // Change back link text
        switch(level){
            case 1:
                $(this).find('span').html(`Close Menu`);
                break;
            case 2:
                $(this).find('span').html(`Back to Menu`);
                break;
            default:
                $(this).find('span').html(`Back to ${$(`li[aria-level="${level-2}"]:visible`).attr('aria-label')}`);
        }
      }
      else{
        // Close the menu
        $(".nav__menu").removeClass("nav__menu--active");
        $(".top-bar").removeClass("top-bar--active");
        // Toggle the hamburger icon
        $(".nav__hamburger-icon").removeClass("nav__hamburger-icon--active");
      }
  });
  /* 
      Trade area navbar (Megamenu) functionnality
  */
  $(".nav__megamenu-col[data-level!=1]").css('display','none');
  $(".nav__megamenu-col").find(".nav__megamenu-col-list-item").removeClass("nav__megamenu-col-list-item").addClass("nav__menu-item");
  $(".nav__megamenu-col").find(".nav__megamenu-col-list-item-link").removeClass("nav__megamenu-col-list-item-link").addClass("nav__menu-item-link");
  // If the user clicks a menu item the appropriate submenu should appear
  $(".meganav").not(".meganav--main").find(".nav__menu-item-link")
  .not(".nav__menu-item-link--back, .nav__menu-item--contact .nav__menu-item-link, .nav__megamenu-col[data-level=4] .nav__menu-item-link").on("click",function(e){
      e.preventDefault();
      $(".nav__menu > .nav__menu-item--contact").css("display","none");
      const level = $(this).closest(".nav__megamenu-col").attr("aria-level");
      const parent = {
          id: $(this).parent().data('id'),
          text: $(this).parent().text().trim()
      }
      // Increase back level
      if(level >= 1 && level < 4){
          const backLink = $(".meganav .nav__menu-item--back");
          backLink.data('level', level);
          const parents = backLink.data('parents') ? backLink.data('parents'):[];
          parents.push(parent);
          backLink.data('parents', parents);
          $(`.nav__megamenu-col[data-level=${level}]`).css('display','none');
          $(`.nav__megamenu-col[data-level=${level+1}]`).css('display','flex');
          $(`.nav__megamenu-col[data-level=${level+1}] .nav__megamenu-col-list-group`).removeClass("nav__megamenu-col-list-group--active");
          $(`.nav__megamenu-col-list-group[data-parent="${parent.id}"]`).addClass("nav__megamenu-col-list-group--active");
          backLink.data('back-level',level+1);
          parent ? backLink.find('span').html(`Back to ${parent.text}`):backLink.find('span').html(`Back to menu`);
      }
  });
  // Go back to the previous menu when back link is clicked
  $(".meganav").not(".meganav--main").find(".nav__menu-item--back").click(function(e){
      // Decrease back level
      const level = $(this).data('level');
      const parents = $(this).data('parents');
      if(parents && level == parents.length){
          parents.pop();
      }
      if(level >= 1 && level < 4){
          $(`.nav__megamenu-col[data-level=${level+1}]`).css('display','none');
          $(`.nav__megamenu-col[data-level=${level}]`).css('display','flex');
          $(this).data('level',level-1);
          parents && parents.length ? $(this).find('span').html(`Back to ${parents.pop().text}`):$(this).find('span').html(`Back to menu`);
      }
      else if(level == 0){
          $(".nav__hamburger-icon").removeClass("nav__hamburger-icon--active");
          $(".nav__menu").removeClass("nav__menu--active");
          $(".top-bar__menu--primary").removeClass("top-bar__menu--primary--active");
          $(".top-bar").removeClass("top-bar--active");
      }
  });
  $(".product-style__navigation-container").addClass("container--hoffset container--left");
  $(".tabs__header-container").removeClass("container");
}
/* Navbar script code (For Desktop)*/   
else{
  // Showing contact directory in desktop 
  $(".nav__menu > .nav__menu-item--contact").css("display","inline-block");
  // Toggle between submenus when a nested menu item is clicked
  $(".nav__submenu").hover(function(e){
      $(this).attr('data-hover',1);
  },function(e){
      $(this).removeClass("nav__submenu--active");
      $(this).attr('data-hover',2);
  });
  $(".nav__menu-item--nested").hover(function(){
      const link = $(this).data('link');
      if(link?.length){
          $(`.nav__megamenu-col[data-level=1]>div[data-link=${link}]`).css('display','unset');
          $(`.nav__megamenu-col[data-level=1]>div[data-link!=${link}]`).css('display','none');
      }
      $(".nav__megamenu").addClass("nav__megamenu--active");
  },function(e){
      let goingTo = e.toElement || e.relatedTarget;
      if(!goingTo.classList.contains('nav__megamenu') && !goingTo.classList.contains('nav__megamenu-col')){
        $(".nav__megamenu").removeClass("nav__megamenu--active");
      }
  }); 
  $(".nav__megamenu").mouseleave(function(e){
      $(this).removeClass("nav__megamenu--active");
      // Remove all the active classes when user moves the mouse out of mega menu
      $(".nav__megamenu-col").find(".nav__megamenu-col-list-item").removeClass("nav__megamenu-col-list-item--active");
      $(".nav__megamenu-col").find(".nav__megamenu-col-list-group").removeClass("nav__megamenu-col-list-group--active");
  });
  $(".top-bar").mouseover(function(e){
      $(".nav__submenu--active").removeClass("nav__submenu--active");
  });
  $(".nav__megamenu-col-list-item").on("click", function(e){
      $(".nav__megamenu").removeClass("nav__megamenu--active");
  });
  // Close the opened menu if an outside click happens
  $(document).click(function(e){
      const target = $(e.target);
      if(!target.parent().hasClass("nav__menu-item--nested") &&
      !target.closest('.nav__submenu').length) {
        $('.nav__submenu').removeClass("nav__submenu--active");
      } 
      if(!target.parent().hasClass("nav__menu-item--nested") &&
      !target.closest('.nav__megamenu').length) {
        $('.nav__megamenu').removeClass("nav__megamenu--active");
      }         
  });
  /* Mega menu desktop functionnality */
  // If the user hover a menu item the appropriate item submenu should appear
  $(".nav__megamenu-col-list-item").on("mouseover", function(e){
      const level = $(this).closest(".nav__megamenu-col").data('level');
      const link = $(this).closest(".nav__megamenu-col").data('level');
      const parent = $(this).data('id');
      $(`.nav__megamenu-col[data-level=${level}]`).find(".nav__megamenu-col-list-item").removeClass("nav__megamenu-col-list-item--active");
      $(this).addClass("nav__megamenu-col-list-item--active");
      $(`.nav__megamenu-col[data-level=${level+1}]`).find(".nav__megamenu-col-list-group").removeClass("nav__megamenu-col-list-group--active");
      $(`.nav__megamenu-col-list-group[data-parent='${parent}']`).addClass("nav__megamenu-col-list-group--active");
      for(let i=level+1;i<=4;i++){
          $(`.nav__megamenu-col[data-level=${i}]`).find(".nav__megamenu-col-list-item").removeClass("nav__megamenu-col-list-item--active");
      }
  });
  // If the user hover over a megamenu parent item show the appropriate childs in the megamenu 
  $(".meganav__menu-item").on("mouseover", function(e){
      const text = $(this).text().trim().split(' ')[0];
      $(`.nav__megamenu-col-list-item:contains(${text})`).mouseover();
  });
  $(".meganav").next(".section--pushed").css("margin-top","7rem");
  $(".nav").next(".hero--shrinked").css("margin-top","7rem");
  $(".product-style__navigation-container").removeClass("container--hoffset container--left");
  $(".product-style__navigation-container").removeClass("container--hoffset container--left");
  $(".tabs__header-container").addClass("container");
  // Show second level menu when firts level menu item is hovered (Products)
  $(".nav__megamenu-col-list--products .nav__megamenu-col-list-item").hover(
      function(e){
          $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").css('display','unset');
      },
      function(e){
          let goingTo = e.toElement || e.relatedTarget;
          
          if(!(goingTo.classList.contains('nav__megamenu-col-list-item-link') || $(goingTo).data('level') == "2")){
              $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").css('display','none');
              $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','none');
          }
      }
  );
  // show third level if user hovers over an element of second level and it has children
  $(".nav__megamenu-col[data-level=2]").find(".nav__megamenu-col-list-item").hover(function(e){
      const parent = $(this).data('id');
      const element = $(".nav__megamenu-col[data-level=3]").find(`.nav__megamenu-col-list-group[data-parent=${parent}]`);
      if(element.length){
          $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','unset');
      }
      else{
        $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','none');
      }
  }, function(e){
      let goingTo = e.toElement || e.relatedTarget;
      if(!(goingTo.classList.contains('nav__megamenu-col-list-item-link') || $(goingTo).data('level') == "3")){
          $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','none');
          console.log("hide");
      }
      else{
          $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','unset');
          console.log("show");
      }
  });
  // Hide third level if user went out of the megamenu
  $(".nav__megamenu").mouseleave(function(e){
    $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','none');
  });
  $(".nav__menu--main .nav__menu-item--nested").hover(function(e){
      const offset = $(this).offset();
      $('.nav__megamenu--main').css('left',offset.left);
  }, function(e){
  });
}




// Resize 
$(window).resize(() => {
    if($(window).width() <= breakpoints.lg){
        // Disable mouseleave event on mobile for mega menu
        $(".nav__megamenu").off("mouseleave");
        $(".nav__menu-item").off("mouseleave mouseenter");
        /* 
            Public navbar functionnality 
        */
        $(".meganav--main .nav__menu-item--nested, .meganav--main .nav__megamenu-col-list-item--nested").on("click", function(e){
            e.preventDefault();
            // Hide all submenus 
            const level = $(this).data("level") != null ? $(this).data("level") : $(this).closest('.nav__megamenu-col').data("level");
            // If it's first level
            if(level == '0'){
                $(".nav__menu > .nav__menu-item").not(".nav__menu-item--back").css('display','none');
                $(`.nav__megamenu-col-list-group`).css('display','none');
                const link = $(this).data("link");
                // Show appropriate submenu
                $(`.nav__megamenu-col[data-level=1]>div[data-link!=${link}]`).css('display','none');
                $(`.nav__megamenu-col[data-level=1]>div[data-link=${link}]`).css('display','unset');
                var parent = {
                    id:0,
                    text:'Menu'
                }
            }
            // Second level
            else if(level == '1'){
                // Show appropriate submenu
                var parent = {
                    id: $(this).data('id'),
                    text: $(this).text().trim()
                }
                $(`.nav__megamenu-col[data-level=1]>div`).css('display','none');
                $(`.nav__megamenu-col-list-group[data-parent=${parent.id}]`).css('display','unset');
            }

            // Increase back level
            if(level >= 0 && level < 4){
                const backLink = $(".meganav .nav__menu-item--back");
                backLink.data('level', level+1);
                console.log(backLink.data('level'));
                const parents = backLink.data('parents') ? backLink.data('parents'):[];
                parents.push(parent);
                backLink.data('parents', parents);
                $(`.nav__megamenu-col[data-level=${level}]`).css('display','none');
                $(`.nav__megamenu-col[data-level=${level+1}]`).css('display','flex');
                $(`.nav__megamenu-col[data-level=${level+1}] .nav__megamenu-col-list-group`).removeClass("nav__megamenu-col-list-group--active");
                $(`.nav__megamenu-col-list-group[data-parent="${parent.id}"]`).addClass("nav__megamenu-col-list-group--active");
                backLink.data('back-level',level+1);
                parent ? backLink.find('span').html(`Back to ${parent.text}`):backLink.find('span').html(`Back to menu`);
            }
        });
        // Go back to the previous menu 
        $(".meganav--main .nav__menu-item--back").click(function(e){
            // Decrease back level
            const level = $(this).data('level');
            const parents = $(this).data('parents');
            if(parents && level == parents.length){
                parents.pop();
            }
            if(level == "0"){
                $(".nav__hamburger-icon").removeClass("nav__hamburger-icon--active");
                $(".nav__menu").removeClass("nav__menu--active");
                $(".top-bar__menu--primary").removeClass("top-bar__menu--primary--active");
                $(".top-bar").removeClass("top-bar--active");
            }
            else if(level == "1"){
                $(".nav__menu > .nav__menu-item").not(".nav__menu-item--back").css('display','unset');
                $(`.nav__megamenu-col[data-level=1]>div`).css('display','none');
                $(this).data('level',level-1);
                parents && parents.length ? $(this).find('span').html(`Back to ${parents.pop().text}`):$(this).find('span').html(`Back`);
            }
            else if(level == "2"){
                $(`.nav__megamenu-col-list-group`).css('display','none');
                $(`.nav__megamenu-col[data-level=1]`).css('display','unset');
                $(`.nav__megamenu-col[data-level=1]>div:first-child`).css('display','unset');
                $(`.nav__megamenu-col[data-level=2]`).css('display','none');
                $(this).data('level',level-1);
                parents && parents.length ? $(this).find('span').html(`Back to ${parents.pop().text}`):$(this).find('span').html(`Back To Menu`);
            }
        });
        /* 
            Trade area navbar (Megamenu) functionnality
        */
        $(".nav__megamenu-col[data-level!=1]").css('display','none');
        $(".nav__megamenu-col").find(".nav__megamenu-col-list-item").removeClass("nav__megamenu-col-list-item").addClass("nav__menu-item");
        $(".nav__megamenu-col").find(".nav__megamenu-col-list-item-link").removeClass("nav__megamenu-col-list-item-link").addClass("nav__menu-item-link");
        // If the user clicks a menu item the appropriate submenu should appear
        $(".meganav").not(".meganav--main").find(".nav__menu-item-link")
        .not(".nav__menu-item-link--back, .nav__menu-item--contact .nav__menu-item-link, .nav__megamenu-col[data-level=4] .nav__menu-item-link").on("click",function(e){
            e.preventDefault();
            $(".nav__menu > .nav__menu-item--contact").css("display","none");
            const level = $(this).closest(".nav__megamenu-col").data('level');
            const parent = {
                id: $(this).parent().data('id'),
                text: $(this).parent().text().trim()
            }
            // Increase back level
            if(level >= 1 && level < 4){
                const backLink = $(".meganav .nav__menu-item--back");
                backLink.data('level', level);
                const parents = backLink.data('parents') ? backLink.data('parents'):[];
                parents.push(parent);
                backLink.data('parents', parents);
                $(`.nav__megamenu-col[data-level=${level}]`).css('display','none');
                $(`.nav__megamenu-col[data-level=${level+1}]`).css('display','flex');
                $(`.nav__megamenu-col[data-level=${level+1}] .nav__megamenu-col-list-group`).removeClass("nav__megamenu-col-list-group--active");
                $(`.nav__megamenu-col-list-group[data-parent="${parent.id}"]`).addClass("nav__megamenu-col-list-group--active");
                backLink.data('back-level',level+1);
                parent ? backLink.find('span').html(`Back to ${parent.text}`):backLink.find('span').html(`Back to menu`);
            }
        });
        // Go back to the previous menu when back link is clicked
        $(".meganav").not(".meganav--main").find(".nav__menu-item--back").click(function(e){
            // Decrease back level
            const level = $(this).data('level');
            const parents = $(this).data('parents');
            if(parents && level == parents.length){
                parents.pop();
            }
            if(level >= 1 && level < 4){
                $(`.nav__megamenu-col[data-level=${level+1}]`).css('display','none');
                $(`.nav__megamenu-col[data-level=${level}]`).css('display','flex');
                $(this).data('level',level-1);
                parents && parents.length ? $(this).find('span').html(`Back to ${parents.pop().text}`):$(this).find('span').html(`Back to menu`);
            }
            else if(level == 0){
                $(".nav__hamburger-icon").removeClass("nav__hamburger-icon--active");
                $(".nav__menu").removeClass("nav__menu--active");
                $(".top-bar__menu--primary").removeClass("top-bar__menu--primary--active");
                $(".top-bar").removeClass("top-bar--active");
            }
        });
        $(".product-style__navigation-container").addClass("container--hoffset container--left");
        $(".tabs__header-container").removeClass("container");
    }
    /* Navbar script code (For Desktop)*/   
    else{
        // Showing contact directory in desktop 
        $(".nav__menu > .nav__menu-item--contact").css("display","inline-block");
        // Toggle between submenus when a nested menu item is clicked
        $(".nav__submenu").hover(function(e){
            $(this).attr('data-hover',1);
        },function(e){
            $(this).removeClass("nav__submenu--active");
            $(this).attr('data-hover',2);
        });
        $(".nav__menu-item--nested").hover(function(){
            const link = $(this).data('link');
            if(link?.length){
                $(`.nav__megamenu-col[data-level=1]>div[data-link=${link}]`).css('display','unset');
                $(`.nav__megamenu-col[data-level=1]>div[data-link!=${link}]`).css('display','none');
            }
            $(".nav__megamenu").addClass("nav__megamenu--active");
        },function(e){
            let goingTo = e.toElement || e.relatedTarget;
            if(!goingTo.classList.contains('nav__megamenu') && !goingTo.classList.contains('nav__megamenu-col')){
              $(".nav__megamenu").removeClass("nav__megamenu--active");
            }
        }); 
        $(".nav__megamenu").mouseleave(function(e){
            $(this).removeClass("nav__megamenu--active");
            // Remove all the active classes when user moves the mouse out of mega menu
            $(".nav__megamenu-col").find(".nav__megamenu-col-list-item").removeClass("nav__megamenu-col-list-item--active");
            $(".nav__megamenu-col").find(".nav__megamenu-col-list-group").removeClass("nav__megamenu-col-list-group--active");
        });
        $(".top-bar").mouseover(function(e){
            $(".nav__submenu--active").removeClass("nav__submenu--active");
        });
        $(".nav__megamenu-col-list-item").on("click", function(e){
            $(".nav__megamenu").removeClass("nav__megamenu--active");
        });
        // Close the opened menu if an outside click happens
        $(document).click(function(e){
            const target = $(e.target);
            if(!target.parent().hasClass("nav__menu-item--nested") &&
            !target.closest('.nav__submenu').length) {
              $('.nav__submenu').removeClass("nav__submenu--active");
            } 
            if(!target.parent().hasClass("nav__menu-item--nested") &&
            !target.closest('.nav__megamenu').length) {
              $('.nav__megamenu').removeClass("nav__megamenu--active");
            }         
        });
        /* Mega menu desktop functionnality */
        // If the user hover a menu item the appropriate item submenu should appear
        $(".nav__megamenu-col-list-item").on("mouseover", function(e){
            const level = $(this).closest(".nav__megamenu-col").data('level');
            const link = $(this).closest(".nav__megamenu-col").data('level');
            const parent = $(this).data('id');
            $(`.nav__megamenu-col[data-level=${level}]`).find(".nav__megamenu-col-list-item").removeClass("nav__megamenu-col-list-item--active");
            $(this).addClass("nav__megamenu-col-list-item--active");
            $(`.nav__megamenu-col[data-level=${level+1}]`).find(".nav__megamenu-col-list-group").removeClass("nav__megamenu-col-list-group--active");
            $(`.nav__megamenu-col-list-group[data-parent='${parent}']`).addClass("nav__megamenu-col-list-group--active");
            for(let i=level+1;i<=4;i++){
                $(`.nav__megamenu-col[data-level=${i}]`).find(".nav__megamenu-col-list-item").removeClass("nav__megamenu-col-list-item--active");
            }
        });
        // If the user hover over a megamenu parent item show the appropriate childs in the megamenu 
        $(".meganav__menu-item").on("mouseover", function(e){
            const text = $(this).text().trim().split(' ')[0];
            $(`.nav__megamenu-col-list-item:contains(${text})`).mouseover();
        });
        $(".meganav").next(".section--pushed").css("margin-top","7rem");
        $(".nav").next(".hero--shrinked").css("margin-top","7rem");
        $(".product-style__navigation-container").removeClass("container--hoffset container--left");
        $(".product-style__navigation-container").removeClass("container--hoffset container--left");
        $(".tabs__header-container").addClass("container");
        $(".nav__megamenu-col-list--products .nav__megamenu-col-list-item").hover(function(e){
            $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").css('display','unset');
            },
            function(e){
                let goingTo = e.toElement || e.relatedTarget;
                
                if(!(goingTo.classList.contains('nav__megamenu-col-list-item-link') || $(goingTo).data('level') == "2"  || $(goingTo).data('level') == "3")){
                    $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").css('display','none');
                    console.log("hide 2");
                }
                else{
                    $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").css('display','unset');
                    console.log("show 2");
                }
            }
        );
        $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").mouseleave(function(e){
            $(this).css('display','none');
        });
        $(".nav__menu--main .nav__menu-item--nested").hover(function(e){
            const offset = $(this).offset();
            $('.nav__megamenu--main').css('left',offset.left);
        }, function(e){
        });
        $(".nav__megamenu-col").find(".nav__menu-item-link").addClass("nav__megamenu-col-list-item-link");
        // show an element of third level if user hovers over an element of second level and it has children
        $(".nav__megamenu-col[data-level=2]").find(".nav__megamenu-col-list-item").hover(function(e){
            const parent = $(this).data('id');
            const element = $(".nav__megamenu-col[data-level=3]").find(`.nav__megamenu-col-list-group[data-parent=${parent}]`);
            if(element.length){
                $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','unset');
            }
        }, function(e){
            let goingTo = e.toElement || e.relatedTarget;
            console.log("level 3 : ",goingTo);
            if(!(goingTo.classList.contains('nav__megamenu-col-list-item-link') || $(goingTo).data('level') == "3")){
                $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','none');
            }
            else{
                $(".nav__megamenu--main .nav__megamenu-col[data-level=3]").css('display','unset');
            }
        });
    }
    $(".nav__megamenu-col-list--products .nav__megamenu-col-list-item").hover(function(e){
        $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").css('display','unset');
    },
    function(e){
        let goingTo = e.toElement || e.relatedTarget;
        
        if(!(goingTo.classList.contains('nav__megamenu-col-list-item-link') || $(goingTo).data('level') == "2"  || $(goingTo).data('level') == "3")){
            $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").css('display','none');
            console.log("hide 2");
        }
        else{
            $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").css('display','unset');
            console.log("show 2");
        }
    });
    $(".nav__megamenu--main .nav__megamenu-col[data-level=2]").mouseleave(function(e){
        $(this).css('display','none');
    });
    $(".nav__menu--main .nav__menu-item--nested").hover(function(e){
        const offset = $(this).offset();
        $('.nav__megamenu--main').css('left',offset.left);
    }, function(e){
    });
});