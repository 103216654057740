// Scroll related events 
$(window).on("scroll", function(e){
  // $(".nav__megamenu").removeClass("nav__megamenu--active");
  // $(".nav__menu").removeClass("nav__menu--active");
  // $(".top-bar").removeClass("top-bar--active");
  // $(".top-bar__menu--primary").removeClass("top-bar__menu--primary--active");
  // $(".nav__hamburger-icon").removeClass("nav__hamburger-icon--active");
});
// Fade in header  
(function () {
  var lastScrollTop = 0;
  $(window).scroll(function(){
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop){
          if($(window).scrollTop()>300){
              $(".hero").not(".hero--white-bg").find(".hero__title, .hero__cta, .hero__paragraph").css("opacity",1 - ($(window).scrollTop()-300) / 200);
              $(".hero").not(".hero--white-bg").find(".hero__title, .hero__cta, .hero__paragraph").css("transform",`translateY(-${$(window).scrollTop()-300}px)`);
          }
      } else {
          $(".hero").not(".hero--white-bg").find(".hero__title, .hero__cta, .hero__paragraph").css("opacity",1 - ($(window).scrollTop()-300) / 200);
          $(".hero").not(".hero--white-bg").find(".hero__title, .hero__cta, .hero__paragraph").css("transform",`translateY(-${Math.max($(window).scrollTop()-300,0)}px)`);
      }
      lastScrollTop = st <= 0 ? 0 : st;
  });
}());

$(window).on("scroll", function(e){

    // Reasons desktop scroll functionality
    if($(".reasons__list-wrapper").length > 0){
        const wS = $(window).scrollTop();
        const eT = $(".reasons__list-wrapper").offset().top;
        const eH = $(".reasons__list-wrapper").outerHeight();
        const qT = $(".testimonial").offset().top;
        const wH = $(this).scrollTop();
        if(wS >= (eT+eH-wH)){
            $(".reasons__list-wrapper")[0].className = 'reasons__list-wrapper reasons__list-wrapper--fixed';
            $(".reasons__list-wrapper").addClass(`reasons__list-wrapper--offset-0`);
            $(".reasons__list-item").removeClass("reasons__list-item--active");
            $(".reasons__list-item").eq(0).addClass("reasons__list-item--active");
        }
        else{
            $(".reasons__list-wrapper").removeClass("reasons__list-wrapper--fixed");
        }
        if(wS >= (eT+eH-wH) + 500){
            $(".reasons__list-wrapper")[0].className = 'reasons__list-wrapper reasons__list-wrapper--fixed';
            $(".reasons__list-wrapper").addClass(`reasons__list-wrapper--offset-25`);
            $(".reasons__list-item").removeClass("reasons__list-item--active");
            $(".reasons__list-item").eq(1).addClass("reasons__list-item--active");
        }
        if(wS >= (eT+eH-wH) + 1100){
            $(".reasons__list-wrapper")[0].className = 'reasons__list-wrapper reasons__list-wrapper--fixed';
            $(".reasons__list-wrapper").addClass(`reasons__list-wrapper--offset-50`);
            $(".reasons__list-item").removeClass("reasons__list-item--active");
            $(".reasons__list-item").eq(2).addClass("reasons__list-item--active");
        }
        if(wS >= (eT+eH-wH) + 1800){
            $(".reasons__list-wrapper")[0].className = 'reasons__list-wrapper reasons__list-wrapper--fixed';
            $(".reasons__list-wrapper").addClass(`reasons__list-wrapper--offset-75`);
            $(".reasons__list-item").removeClass("reasons__list-item--active");
            $(".reasons__list-item").eq(3).addClass("reasons__list-item--active");
        }
        if(wS >= (eT+eH-wH) + 2350){
            $(".reasons__list-wrapper")[0].className = 'reasons__list-wrapper reasons__list-wrapper--offset';
            $(".reasons__list-wrapper").addClass(`reasons__list-wrapper--offset-75`);
        }
        // Testimonial desktop functionnality
        if(wS >= 4350){
            $(".testimonial")[0].className = "testimonial testimonial--fixed";
        }
        else{
            $(".testimonial")[0].className = "testimonial";
        }
        $(".testimonial--fixed .testimonial__quote").css({
            top:`${240 + (wS - qT)/10}px`
        })
        $(".testimonial--fixed .testimonial__container").css({
            top:`${33 - (wS - qT)/200}%`
        });
    }
});
