// Media query breakpoints
const breakpoints = {
    'sm':576,
    'md':768,
    'lg':992,
    'xl':1200,
    'xxl':1600
  }
/* Slick slider JS code */ 
// Quotes cards slider
$('.qcard-container__slider').slick({
    centerMode:true,
    autoplay:false,
    speed:1000,
    slidesToShow:1,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    prevArrow:$('.qcard-container__arrows--left'),
    nextArrow:$('.qcard-container__arrows--right'),
    centerPadding:0
});
// Partners logos slider
$('.partners__slider').each((ind,elm) => {  	
    $(elm).slick({	
        arrows: false,	
        dots: true,	
        variableWidth: true,	
        autoplay: true,	
        autoplaySpeed: 1000,	
        responsive: [	
            {	
                breakpoint: breakpoints.md,	
                settings: {	
                    slidesToShow: 1,	
                    infinite: true,	
                    dots: true,	
                    centerMode:true,	
                    slidesToScroll: 1,	
                }	
            },	
            {	
                breakpoint: breakpoints.xl,	
                settings: {	
                    slidesToShow: 4,	
                    infinite: true,	
                    dots: false,	
                    slidesToScroll: 2,	
                }	
            },	
            {	
                breakpoint: 10000,	
                settings: {	
                    slidesToShow: 7,	
                    centerMode:true,	
                    variableWidth:true,	
                    dots: false,	
                    autoplay: true,	
                    autoplaySpeed: 3000,	
                    slidesToScroll: 2,	
                }	
            }	
        ],	
        appendDots:$(elm).siblings(".partners__slider-dots")	
    });	
});
// Sectors slider
$(".sectors__slider--mobile-on").slick({
    infinite: true,
    prevArrow:$(".sectors__slider-arrow-left"),
    nextArrow:$(".sectors__slider-arrow-right"),
    centerMode: true,
    centerPadding: '0px',
    responsive:[
        {
            breakpoint:10000,
            settings:{
                variableWidth: true,
                slidesToShow: 2,
            }
        },
        {
            breakpoint:500,
            settings:{
                variableWidth: false,
                slidesToShow: 1,
                centerMode:true
            }
        },
    ]
});
$(".sectors__slider--mobile-off").slick({
    infinite: true,
    prevArrow:$(".sectors__slider-arrow-left"),
    nextArrow:$(".sectors__slider-arrow-right"),
    centerMode: true,
    centerPadding: '0px',
    responsive:[
        {
            breakpoint:10000,
            settings:{
                variableWidth: true,
                slidesToShow: 2,
            }
        },
        {
            breakpoint:576,
            settings:"unslick"
        },
    ]
});
// Tile slider
$(".tile__slider-container").each((ind, elm) => {
    elm = $(elm);
    elm.find(".tile__slider").slick({
        slidesToShow:1,
        prevArrow:elm.find(".tile__slider-arrow-left"),
        nextArrow:elm.find(".tile__slider-arrow-right"),
        accessibility: false,
        responsive:[
            {
                breakpoint:breakpoints.md,
                settings:{
                    variableWidth:false
                }                
            },
            {
                breakpoint:10000,
                settings:{
                    variableWidth:true
                }
            }
        ]
    });
});
// Full screen gallery slider
$(".fs-gallery__slider").slick({
    prevArrow:$(".fs-gallery__arrows--left"),
    nextArrow:$(".fs-gallery__arrows--right"),
    slidesToShow:1,
});
// Product preview slider 
$(".product-card__gallery").each((ind, elm) => {
    elm = $(elm);
    elm.slick({
        slidesToShow:1,
        prevArrow:elm.next().find(".product-card__gallery-arrows--left"),
        nextArrow:elm.next().find(".product-card__gallery-arrows--right"),
    });
});
// Projects slider 
$(".projects__slider-container").slick({
  infinite:false,
  prevArrow:$(".projects__slider-arrows--left"),
  nextArrow:$(".projects__slider-arrows--right"),
  responsive:[
      {
          breakpoint:breakpoints.lg,
          settings:{
              slidesToShow:1,
              infinite:true
          }
      },
      {
          breakpoint:10000,
          settings:{
              variableWidth:true,
              infinite:true,
              slidesToShow:2
          }
      }
  ]
});
// Tabs header slider
$(".tabs__header").slick({
  arrows:false,
  dots:true,
  infinite:false,
  responsive:[
      {
          breakpoint:992,
          settings:{
              slidesToShow:1.1,
          }
      },
      {
          breakpoint:10000,
          settings:"unslick"
      }
  ]
});
$(".tabs__header-item").on("click", function(e){
  const index = parseInt($(this).data("index"));
  if($(window).width() > 992){
      index == 0 ? $(this).closest(".tabs__header").removeClass("tabs__header--offset"):$(".tabs__header").addClass("tabs__header--offset")
      $(this).addClass("tabs__header-item--active");
      $(this).siblings(".tabs__header-item").removeClass("tabs__header-item--active");
      $(this).closest(".tabs").find(".tabs__item").removeClass("tabs__item--active");
      $(this).closest(".tabs").find(".tabs__item").eq(index).addClass("tabs__item--active");     

  }
  else{
      $(this).closest(".tabs__header").slick('slickGoTo', index);
  }
});
$(".tabs__header").on("beforeChange", function(slick, event, currentSlide, nextSlide){
  $(this).closest(".tabs").find(".tabs__item").removeClass("tabs__item--active");
  $(this).closest(".tabs__header-item").removeClass("tabs__header-item--active");
  if(currentSlide == 0){
    $(this).closest(".tabs").find(".tabs__item").eq(1).addClass("tabs__item--active");
    $(this).closest(".tabs__header-item").eq(1).addClass("tabs__header-item--active");
  }
  else{
    $(this).closest(".tabs").find(".tabs__item").eq(0).addClass("tabs__item--active");
    $(this).closest(".tabs__header-item").eq(0).addClass("tabs__header-item--active");
  }  
});
// Product style navigation slider 
$(".product-style__navigation-slider").slick({
  responsive:[
      {
          breakpoint:992,
          settings:{
              slidesToShow:1,
              arrows:false,
              dots:true,
              infinite:false,
              appendDots:$(".product-style__navigation-dots")
          }
      },
      {
          breakpoint:10000,
          settings:"unslick"
      }
  ]
});

// Product style slider
$(".product-style__slider").slick({
  slidesToShow:1,
  variableWidth: true,
  centerMode:true,
  arrows:false,
  infinite:false,
  responsive:[
      {
          breakpoint:992,
          settings:{
              variableWidth:false,
          }
      },
  ]
});
// Change product style navigation when slider moves
$(".product-style__slider").on("beforeChange", function(slick, event, currentSlide, nextSlide){
  if($(window).width()<= breakpoints.lg){
      $(".product-style__navigation-slider").slick('slickGoTo', nextSlide);
  }
  else{
      $(".product-style__navigation-item").eq(nextSlide).click();
  }
});
// Change product style slide if the navigation changes
$(".product-style__navigation-slider").on("beforeChange", function(slick, event, currentSlide, nextSlide){
  $(".product-style__slider").slick('slickGoTo', nextSlide);
});
$(".product-style__navigation-item").on("click", function(e){
  const index = $(".product-style__navigation-item").index($(this));
  $(".product-style__slider").slick('slickGoTo', index);
  $(".product-style__navigation-item").removeClass("product-style__navigation-item--active");
  $(this).addClass("product-style__navigation-item--active");
});

// product ranges slider
$(".pr__slider").each((ind, elm) => {
    elm = $(elm);
    elm.slick({
        arrows:true,
        infinite:false,
        prevArrow:elm.next().find(".pr__slider-arrow--prev"),
        nextArrow:elm.next().find(".pr__slider-arrow--next"),
        responsive:[
            {
                breakpoint: breakpoints.md,
                settings:{
                    slidesToShow:1,
                }
            },
            {
                breakpoint: breakpoints.lg,
                settings:{
                    slidesToShow:2,
                }
            },
            {
                breakpoint:10000,
                settings:{
                    slidesToShow:3
                }
            }
        ]
    })
});

//Target cards slider
$(".t-cards__slider").slick({
    arrows:true,
    prevArrow: $(".t-cards__slider-arrow--prev"),
    nextArrow: $(".t-cards__slider-arrow--next"),
    infinite:false,
    responsive:[
        {
            breakpoint: breakpoints.sm,
            settings:{
                slidesToShow:1,
            }
        },
        {
            breakpoint: breakpoints.xl,
            settings:{
                slidesToShow:2,
            }
        },
        {
            breakpoint: 10000,
            settings:"unslick"
        }
    ]
});

//Research slider
$(".research__slider").slick({
    infinite:false,
    arrows:true,
    prevArrow: $(".research__arrow--prev"),
    nextArrow: $(".research__arrow--next"),
    responsive:[
        {
            breakpoint: breakpoints.lg,
            settings:{
                slidesToShow:1
            }
        },
        {
            breakpoint: 10000,
            settings:{
                slidesToShow:2
            }
        }
    ]
})

$(".news__articles-gallery").each((ind, elm) => {
    elm = $(elm);

    elm.slick({
        infinite:true,
        arrows:true,
        prevArrow: elm.next().find(".news__articles-gallery-arrows--prev"),
        nextArrow: elm.next().find(".news__articles-gallery-arrows--next"),
        responsive:[
            {
                breakpoint: breakpoints.lg,
                settings:{
                    slidesToShow:1
                }
            },
            {
                breakpoint: 10000,
                settings:"unslick"
            }
        ]
    });
});
$(".txt-slider__slider").slick({
    slidesToShow: 1,
    prevArrow: $(".txt-slider__slider-arrow--prev"),
    nextArrow:$(".txt-slider__slider-arrow--next")
})