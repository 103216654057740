// Media query breakpoints
const breakpoints = {
  'sm':576,
  'md':768,
  'lg':992,
  'xl':1200,
  'xxl':1600
}
// Throttle function 
let timerId;
const throttle = function (func, delay) {
    // If setTimeout is already scheduled, no need to do anything
    if (timerId) {
        return
    }
    // Schedule a setTimeout after delay seconds
    timerId  =  setTimeout(function () {
        func()
        // Once setTimeout function execution is finished, timerId = undefined so that in <br>
        // the next scroll event function execution can be scheduled by the setTimeout
        timerId  =  undefined;
    }, delay)
}

$(document).ready(function(e){
    // Verify if js is working
  document.documentElement.classList.remove("no-js");
  // Open navbar when hamburger icon is clicked
  $(".nav__hamburger-icon").click((e) => {
      $(".nav__hamburger-icon").toggleClass("nav__hamburger-icon--active");
      $(".nav__menu").toggleClass("nav__menu--active");
      $(".top-bar__menu--primary").toggleClass("top-bar__menu--primary--active");
      $(".top-bar").toggleClass("top-bar--active");
  });
  // Hide desktop reasons
  if($(window).width() <= breakpoints.xl){
  $(".reasons").attr("hidden","");
  $(".section--accordion").removeAttr("hidden");
  }
  // Hide mobile reasons
  else{
    $(".section--accordion").attr("hidden","");
    $(".reasons").removeAttr("hidden");
  }
  // Toggle between contact directory tabs when they're clicked
  $(".contacts-dir__element-panel-item").on("click", function(e) {
    if(!$(this).hasClass('contacts-dir__element-panel-item--active')){
      // Highlight the active tab
      $(this).siblings().removeClass('contacts-dir__element-panel-item--active');
      $(this).addClass('contacts-dir__element-panel-item--active');
      // Toggle between tabs bodies
      $(this).closest(".contacts-dir__element-header").siblings(".contacts-dir__element-body").removeClass("contacts-dir__element-body--active");
      const label = $(this).data('panel-label');
      // Active the clicked tab body using the appropriate label
      $(this).closest(".contacts-dir__element-header").siblings(`.contacts-dir__element-body[data-label=${label}]`).addClass("contacts-dir__element-body--active");
    }
  });
  // Code that needs to be executed to set some styles for different media queries
  /* Small size devices (Mobile & Tablet)*/
  if($(window).width()<= breakpoints.md){
    $(".partners__slider-element").css("opacity","1");
  }
  /* Big size devices (Laptops & Desktop)*/
  else{
    $(".login__title").html("Login or Create Account");
    $(".contact__form-wrapper").not(".contact__form-wrapper--modal").find(".contact__form-title").html("Let's talk about your project");
  }
  // Show video modal when play button is clicked
  $(".videos-grid__element").on("click", function(e){
    // Load the iframe video
    if(!e.target.classList.contains('videos-grid__element-modal-header-icon')){
      const src = $(this).find(".videos-grid__element-modal-body").data("src");
      $(this).find(".videos-grid__element-modal-video").attr('src',src);
      $(this).find(".videos-grid__element-modal").removeClass("videos-grid__element-modal--hidden");
      $("body").addClass("blocked");
    }
  });
  // Close video modal when close button is clicked
  $(".videos-grid__element-modal-header-icon").on("click", function(e){
    e.preventDefault();
    console.log("clicked");
    $(this).closest(".videos-grid__element-modal").addClass("videos-grid__element-modal--hidden");
    $("body").removeClass("blocked");
    const iframe = $(this).parent().next().find(".videos-grid__element-modal-video");
    iframe.attr('src','');
  });
  // Expand the gallery when full screen button is clicked 
  $(".product-card__fs-btn").on("click", function(e){
    const productCard = $(this).closest(".product-card");
    const buttonText = $(this).children(".product-card__fs-btn-txt");
    const buttonIconOpen = $(this).children(".product-card__fs-btn-icon--open");
    const buttonIconClose = $(this).children(".product-card__fs-btn-icon--close");
    $(".product-card").not(productCard).removeClass("product-card--open");
    $(".product-card__fs-btn-txt").not(buttonText).attr('data-before','View Fullscreen gallery');
    productCard.toggleClass("product-card--open");
    if(productCard.hasClass('product-card--open')){
      buttonText.attr('data-before','Close Gallery');
      buttonIconOpen.css("display","none");
      buttonIconClose.css("display","block");
      if(productCard.find('.product-card__gallery-item').length > 1){
        $(this).siblings(".product-card__gallery-arrows").children().removeClass("slick-arrow slick-hidden");
      }
    }
    else{
      buttonText.attr('data-before','View Fullscreen gallery');
      buttonIconOpen.css("display","block");
      buttonIconClose.css("display","none");
    }
  });
  // Expand the advanced tiles when button is clicked
  $(".tile--advanced .tile__slider-button").on("click", function(e){
      e.preventDefault();
      const currentTile = $(this).closest(".tile--advanced");
      const buttonContent = $(this).children(".tile__slider-button-content");
      currentTile.toggleClass("tile--advanced-open");
      $(this).closest(".tile--advanced").find(".tile__slider")[0].slick.refresh(); 
      $(this).closest(".tile--advanced").find(".tile__slider").slick('setPosition');   
      if(currentTile.hasClass("tile--advanced-open")){
        buttonContent.text("Close gallery");
        $(this).find("button i").css("transform","rotate(180deg)");
      }
      else{
        buttonContent.text("View gallery");
        $(this).find("button i").css("transform","rotate(0deg)");
      }

  });
  // Expand advanced discover more cards (About company) when button is clicked
  $(".card--advanced").on("click", function(e){
    e.preventDefault();
    const currentCard = $(this).closest(".card--advanced");
    // If current is open
    if(currentCard.hasClass('card--advanced-open')){
      currentCard.find(".container").css("opacity","0");
      currentCard.removeClass("card--advanced-open");
      $(".card--advanced").removeClass("card--advanced-closed");
    }
    // if it's closed
    else{
      currentCard.find(".container").css("opacity","1");
      setTimeout(function(e){
        $(".card--advanced").removeClass("card--advanced-open");
        $(".card--advanced").not(currentCard).addClass("card--advanced-closed");
        currentCard.removeClass("card--advanced-closed");
        currentCard.addClass("card--advanced-open");
      },50);
    }
  });
  // Make the whole card area clickable 
  $(".card").not(".card--advanced").on("click", function(e){ 
    e.preventDefault();
    const link = $(this).find(".card__button").attr("href");
    window.location.href=link;
  });
  // Add specific classes to the first paragraph of each copy component 
  $(".text-wrapper").find("p").first().addClass("txt--serif h3 lh-2");
  $(".text-wrapper").find("ul").first().addClass("ml-1 mb-1");
  // Scroll and open cards when about subitems are clicked
  $(".nav__menu-item-link--scroll, .footer__menu-item-link--scroll").on("click" ,function(e){
      // Store in sessionStorage the clicked link card index
      sessionStorage.setItem('card-index',$(this).data("card-index"));
  });
  const cardIndex = sessionStorage.getItem('card-index');
  if(cardIndex){
      $([document.documentElement, document.body]).animate({
          scrollTop: $("#advanced-cards").offset().top - 80
      }, 1000);
      setTimeout(function(){
          const cardButton = $("#advanced-cards").find(".card").find(".card__button");
          cardButton[cardIndex-1].click();
          sessionStorage.removeItem('card-index');
      },1000);
  }
  // Hide fs gallery button if slider contains only one element 
  $(".product-card").each( (index, prd) => {
      let product = $(prd);
      const productItemsCount = product.find(".product-card__gallery-item").length;
      if(productItemsCount==1){
          product.find(".product-card__fs-btn").addClass("product-card__fs-btn--disabled");
      }
  });
  // Code that needs to be executed when a window resize happen 
  $(window).resize(() => {
    throttle(function(){
        if($(window).width()<=breakpoints.md){
            $(".partners__slider-element").css("opacity","1");
            // Adjust login title breakling
            $(".login__title").html("Login or<br>Create Account");
            $(".contact__form-wrapper").not(".contact__form-wrapper--modal").find(".contact__form-title").html("Let's talk about <br>your project");
            // change megamenu item style
            $(".product-style__navigation-container").addClass("container--hoffset container--left");
            $(".tabs__header-container").removeClass("container");
        }
        else{
            $(".login__title").html("Login or Create Account");
            $(".contact__form-wrapper").not(".contact__form-wrapper--modal").find(".contact__form-title").html("Let's talk about your project");
            $(".product-style__navigation-container").removeClass("container--hoffset container--left");
            $(".partners__slider").each(function(){
                // Get the count of slider elements
                const length = $(this).children(".partners__slider-element").length;  
                $(this).children(".partners__slider-element").each(function(ind,elm){
                    $(elm).css('opacity',`${Math.sin((Math.PI*ind)/(length-1))+.2}`);
                })
            });
            $(".tabs__header-container").addClass("container");
        }
        // Refresh slick slider 
        $(".partners__slider").each(function(){
            $(this)[0].slick.refresh();
        });
        if($(window).width() <= breakpoints.xl){
            // Hide desktop reasons
            $(".reasons").attr("hidden","");
            $(".section--accordion").removeAttr("hidden");
        }
        else{
            // Hide mobile reasons
            $(".section--accordion").attr("hidden","");
            $(".reasons").removeAttr("hidden");
        }
    },500);
    $(".text-img__list-intro").each(function(){
      const text = $(this).data('text');
        if($(window).width() < 992){
          const truncatedText = text.split(' ').slice(0, 14).join(' ') + '...';
          $(this).text(truncatedText);
        }
        else{
          $(this).text(text);
        }
    });
  });
  // Give product style heading dynamic width so they sit in two lines 
  $(".product-style__content .product-style__heading").each((index, element) => {
    console.log($(element).text().length);
    $(element).css({
        'max-width':`${($(element).text().length / 2) * 21}px`
    });
  });
  $(".h-accordion__item").on("click", function(e){
    $(".h-accordion__item").not($(this)).removeClass("h-accordion__item--active");
    $(this).toggleClass("h-accordion__item--active");
  });
  $(".h-accordion__item--alt").on("click", function(e){
    $(this).prev().addClass('h-accordion__item--test')
  });
  $(".h-accordion__item--left").on("click", function(e){
    $(this).next().removeClass('h-accordion__item--test')
  });
  $(".h-accordion__item .h-accordion__item-heading").on("click", function(e){
    e.stopPropagation();
    if($(this).data('link')?.length){
        window.location.replace($(this).data('link'));
    }
  });
  $('.product-style__bottom,.product-style__button').on("click", function(e){
    $(this).closest('.product-style__item').toggleClass('product-style__item--active');
  });
  $(".btn--scroll").on("click", function(e){
    const elementId = $(this).data('id');
    $([document.documentElement, document.body]).animate({
        scrollTop: $(`#${elementId}`).offset().top
    }, 0);
  });
  $(".reasons__list-item").on("click", function(e){
    const index = $(".reasons__list-item").index($(this));
    $(".reasons__list-item").removeClass("reasons__list-item--active");
    $(this).addClass("reasons__list-item--active");
    $(".reasons__list-wrapper")[0].className = "reasons__list-wrapper";
    $(".reasons__list-wrapper").addClass(`reasons__list-wrapper--offset-${index * 25}`);
    const targetElement = $(".reasons__item").eq(index);
    $([document.documentElement, document.body]).animate({
        scrollTop: targetElement.offset().top - targetElement.height() / 2
    }, 0);
    
  });
  // case studies filter toggle functionnality 
  $(".filter__header").on("click", function(e){
    $(".filter__container").toggleClass("filter__container--active");
  });
  $(".filter__item").on("click", function(e){
    $(".cs-cards__item").css('display','flex');
    if($(this).hasClass("filter__item--full")){
        $(".filter__item").not($(this)).removeClass("filter__item--active");
        $(this).addClass("filter__item--active");
    }
    else{
        $(this).toggleClass("filter__item--active");
        $(".filter__item--full").removeClass("filter__item--active");
        // Get selected sectors 
        const sectorsArr = [];
        $(".filter__item--active").each((ind, elm) => {
            const sector = $(elm).data('sector');
            sectorsArr.push(sector);
        });
        let activeCsCards = $.map(sectorsArr,
            function (item) {
              return ".cs-cards__item[data-sector=" + item + "]";
            }).join(",");
        $(".cs-cards__item").not($(activeCsCards)).css('display','none');
    }
    const activeFiltersCount = $(".filter__item--active").length;
    if(activeFiltersCount == 0){
        $(".cs-cards__item").css('display','flex');
        $(".filter__item").not($(".filter__item--full")).removeClass("filter__item--active");
        $(".filter__item--full").addClass("filter__item--active");
    }
  });
  $(".filter__footer").on("click", function(e){ 
    $(".filter__container").removeClass("filter__container--active");
  });
  // Hide the contact directry entries when the filter selectbox is changed
  $('.contacts-dir__select').on('change',function(){
    const filterOption = $(this).find("option:selected").attr('value');
    // Show all the elements if the chosen value is all
    if(filterOption == 'all'){
        $(".contacts-dir__element").show();
    }
    // Hide the other entries if a specific filter option is chosen
    else{
        $(`.contacts-dir__element[data-type=${filterOption}]`).show();
        $(`.contacts-dir__element[data-type!=${filterOption}]`).hide();
    }
  });
  // Accordion code
  $(".section__accordion-item").on("click", function(e){
    $(this).toggleClass("section__accordion-item--active");
    $(".section__accordion-item").not($(this)).removeClass("section__accordion-item--active");
  });
  $(".faq__item").on("click", function(e){
    $(this).toggleClass("faq__item--active");
    $(".faq__item").not($(this)).removeClass("faq__item--active");
  });
  // Open contact modal when CTA button is clicked
  $(".btn--modal").on("click", function(e){
    $(".modal").addClass("modal--active");
    $("body").addClass("blocked");
    const index = $(this).data('tab-index');
    console.log("index : ", index);
    $(".modal").find(".tabs__header-item").eq(+index).click();
  });
});
// Close modal when click outside of it
$(document).mouseup(function(e){
  const modal = $(".modal");
  if (!modal.is(e.target) && modal.has(e.target).length === 0){
    modal.removeClass("modal--active");
    $("body").removeClass("blocked");
  }
});
// Truncate text img list items text 
$(".text-img__list-intro").each(function(){
  const text = $(this).data('text');
    if($(window).width() < 992){
      const truncatedText = text.split(' ').slice(0, 14).join(' ') + '...';
      $(this).text(truncatedText);
    }
    else{
      $(this).text(text);
    }
});
// Show more button functionality
$(".text-img__list-cta").on("click", function(e){
    if($(window).width() < 992){
      const fullText = $(this).prev().data('text');
      const truncatedText = fullText.split(' ').slice(0, 14).join(' ') + '...';
      if($(this).hasClass("text-img__list-cta--active")){
          $(this).prev().text(truncatedText);
          $(this).removeClass("text-img__list-cta--active");
          $(this).find(".text-img__list-cta-text").text("Read More");
      }
      else{
          $(this).prev().text(fullText);
          $(this).addClass("text-img__list-cta--active");
          $(this).find(".text-img__list-cta-text").text("Read Less");
      }
    }
});
$(".links__section-toggle").on("click", function(e){
  $(this).closest(".links__section").toggleClass("links__section--active");
  if($(this).closest(".links__section").hasClass("links__section--active")){
    $(this).find(".links__section-toggle-text").text("Close");
  }
  else{
    $(this).find(".links__section-toggle-text").text("Expand");
  }
});
$(".research__item-more").on("click", function(e){
  const parentElement = $(this).closest(".research__item");
  parentElement.toggleClass("research__item--active");
  if(parentElement.hasClass("research__item--active")){
    parentElement.find(".research__item-more-text").text("Read Less");
    const fullText = parentElement.find(".research__item-intro").data('full-content');
    parentElement.find(".research__item-intro").text(fullText);
  }
  else{
    parentElement.find(".research__item-more-text").text("Read More");
    const truncatedText = parentElement.find(".research__item-intro").data('full-content').slice(0, 200) + '...';
    parentElement.find(".research__item-intro").text(truncatedText);
  }
});

// Execute this code on each window resize
if($(window).width() > 992){
  $(".research__item").on("mouseover", function(e){
    $(this).addClass("research__item--active");
    const textElement = $(this).find(".research__item-intro");
    const fullText = textElement.data('full-content');
    textElement.css('opacity','0');
    textElement.text(fullText);
    textElement.animate({opacity: 1}, 500);
  });
  $(".research__item").on("mouseleave", function(e){
    $(this).removeClass("research__item--active");
    const truncatedText = $(this).find(".research__item-intro").data('full-content').slice(0, 200) + '...';
    $(this).find(".research__item-intro").text(truncatedText);
  });
}
else{
  // remove event listeners on mobile
  $(".research__item").off("mouseover");
  $(".research__item").off("mouseleave");
}
$(window).resize(function(){
  if($(window).width() > 992){
    $(".research__item").on("mouseover", function(e){
      $(this).addClass("research__item--active");
      const textElement = $(this).find(".research__item-intro");
      const fullText = textElement.data('full-content');
      textElement.css('opacity','0');
      textElement.text(fullText);
      textElement.animate({opacity: 1}, 500);
    });
    $(".research__item").on("mouseleave", function(e){
      $(this).removeClass("research__item--active");
      const truncatedText = $(this).find(".research__item-intro").data('full-content').slice(0, 200) + '...';
      $(this).find(".research__item-intro").text(truncatedText);
    });
  }
  else{
    // remove event listeners on mobile
    $(".research__item").off("mouseover");
    $(".research__item").off("mouseleave");
    $(".research__item").removeClass("research__item--active");
  }
});

$(".header__video").on("click", function(e){
  $(this).play();
});
// Show video modal when play button is clicked
$(".modal-btn").on("click", function(e){
  $(".modal").addClass("modal--active");
  $("body").addClass("blocked");
  $(".modal__video")[0].play()
});

// Close video modal when close button is clicked
$(".modal__header-icon").on("click", function(e){
  e.preventDefault();
  $(this).closest(".modal").removeClass("modal--active");
  $("body").removeClass("blocked");
});